$font-path: '../../../assets/fonts/';

@font-face {
  font-family: 'GEA Sans';
  src: url($font-path + 'GEASans/GEASans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'GEA Sans';
  src: url($font-path + 'GEASans/GEASans-Bold.ttf') format('truetype');
  font-weight: bold;
}

// Regular size
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

// Medium Size
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// SemiBold
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

// Bold
@font-face {
  font-family: 'GEA Inter';
  src: url($font-path + 'Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

// after all teams are using ortus, we can drop the class prefix for default fonts
.ortus-light-theme * {
  font-family: 'GEA Inter', 'GEA Sans', serif;
}
