.p-togglebutton.p-button {
  background: $toggleButtonBg;
  border: $toggleButtonBorder;
  border-radius: 4px;
  color: $toggleButtonTextColor;
  transition: $formElementTransition;

  .p-button-icon-left,
  .p-button-icon-right {
    color: $toggleButtonIconColor;
  }

  &:not(.p-disabled):not(.p-highlight):hover {
    background: $toggleButtonHoverBg;
    border-color: $toggleButtonHoverBorderColor;
    color: $toggleButtonTextHoverColor;

    .p-button-icon-left,
    .p-button-icon-right {
      color: $toggleButtonIconHoverColor;
    }
  }

  &.p-highlight {
    background: $toggleButtonActiveBg;
    border-color: $toggleButtonActiveBorderColor;
    color: $toggleButtonTextActiveColor;

    .p-button-icon-left,
    .p-button-icon-right {
      color: $toggleButtonIconActiveColor;
    }

    &:hover {
      background: $toggleButtonActiveHoverBg;
      border-color: $toggleButtonActiveHoverBorderColor;
      color: $toggleButtonTextActiveHoverColor;

      .p-button-icon-left,
      .p-button-icon-right {
        color: $toggleButtonIconActiveHoverColor;
      }
    }
  }
  &:not(.p-disabled):not(.p-highlight):hover {
    &.geaui-pressed {
      background-color: $geaui-secondary-pressed;
      border-color: $geaui-primary-pressed;
    }
  }
  &.p-highlight:hover {
    &.geaui-pressed {
      background-color: $geaui-primary-pressed;
      border-color: $geaui-primary-pressed;
    }
  }
  &:focus {
    border-color: $geaui-focused-outline;
  }
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  @include invalid-input();
}

.p-button.geaui-chip-small {
  @include gea-font-heading(16);
  color: $toggleButtonTextColor;
  height: 30px;
  padding: 4px;
  gap: 0;
}

.p-button.geaui-chip-medium {
  @include gea-font-button(20);
  color: $toggleButtonTextColor;
  height: 50px;
  padding: 11px 16px;
  gap: 4px;
}

// disabled states
.p-togglebutton.p-disabled {
  background-color: transparent;
  border-color: $geaui-grey-03;
  color: $geaui-grey-01;
  &.p-highlight {
    background-color: $geaui-grey-04;
    border-color: $geaui-grey-03;
    color: $geaui-grey-01;
  }
}
