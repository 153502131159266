.p-dialog {
  border-radius: $borderRadius;
  box-shadow: $overlayContainerShadow;
  border: $overlayContentBorder;
  min-width: 45vw;

  .p-dialog-header {
    @include gea-font-heading(24);
    background: $dialogHeaderBg;
    color: $geaui-pure-black;
    padding: 24px 50px 0 40px;
    margin: 0;
    height: 100%;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    position: relative;

    .p-dialog-title {
      font-weight: $dialogHeaderFontWeight;
      font-size: $dialogHeaderFontSize;
    }

    .p-dialog-header-icon {
      margin-right: $inlineSpacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .p-dialog-content {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    padding: $dialogContentPadding;
    @include gea-font-description(16);
    padding: 24px 50px 0 40px;
    display: inline-block;

    &:last-of-type {
      border-bottom-right-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
  }

  .p-dialog-footer {
    background: $overlayContentBg;
    color: $panelFooterTextColor;
    padding: 43px;
    text-align: right;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    button {
      margin: 0 $inlineSpacing 0 0;
      width: auto;
    }
  }

  &.p-confirm-dialog {
    .p-confirm-dialog-icon {
      font-size: $primeIconFontSize * 2;

      &.p-icon {
        width: $primeIconFontSize * 2;
        height: $primeIconFontSize * 2;
      }
    }

    .p-confirm-dialog-message {
      margin-left: $inlineSpacing * 2;
    }
  }
}

.dialog-container .p-dialog {
  width: 45vw;
}

.gea-dialog-icon {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}

.gea-dialog-btn {
  margin-left: 4px;
}

.no-title .p-dialog .p-dialog-header {
  height: 60px;
}

.p-dynamic-dialog {
  .p-dialog-footer {
    padding: 0;
  }
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  geaui-complex-dialog-header {
    width: 100%;
    .complex-dialog-header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 72px;
      padding: 16px 40px;
    }
    .complex-dialog-close-icon {
      display: block;
      cursor: pointer;
    }
  }

  geaui-complex-dialog-footer {
    .complex-dialog-footer-content {
      padding: 16px 40px;
    }
  }
}


.complex-dialog-container .p-dialog .p-dialog-header {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0;
  background: $dialogHeaderBg;
  color: $geaui-pure-black;
  padding: 16px 40px;
  margin: 0;
  height: 72px;
  position: relative;
  border-bottom: $geaui-grey-04 2px solid;
}

.complex-dialog-container .p-dialog .p-dialog-footer {
  background: $geaui-pure-white;
  padding: 16px 40px;
  text-align: right;
  border-top: $geaui-grey-04 2px solid;
}

.complex-dialog-container .p-dialog .p-dialog-content {
  padding: 20px 40px;
}

.complex-dialog-container.no-border-top-footer .p-dialog .p-dialog-footer {
  border-top: none;
}

.complex-dialog-container.no-borders .p-dialog {
  .p-dialog-header,
  .p-dialog-footer {
    border: none;
  }
}

.complex-dialog-container.no-header .p-dialog .p-dialog-header {
  display: none;
}

// Give Dialog more space on smaller screens
// Tablet large
@media (max-width: 1439px) and (min-width: 1024px) {
  .p-dialog {
    width: 60vw;
  }
}

// Tablet small
@media (max-width: 1023px) and (min-width: 768px) {
  .p-dialog {
    width: 70vw;
  }
}

// Give Modal more space on smaller screens
// Tablet large
geaui-modal-v2 {

  @media (max-width: 1023px) {
    .p-dialog {
      width: 95vw;
    }
  }

  @media (max-width: 1439px) and (min-width: 1024px) {
    .p-dialog {
      width: 95vw;
    }
  }

  // Tablet small
  @media (max-width: 1023px) and (min-width: 768px) {
    .p-dialog {
      width: 95vw;
    }
  }
}
