.geaui-tooltip {
  display: inline-flex;
  width: 100%;
  padding: 16px;

  &.geaui-tooltip-type-blue {
    background-color: $geaui-active-blue-50;

    .geaui-tooltip-content, .geaui-tooltip-title {
      color: $geaui-primary-black;
    }
  }

  &.geaui-tooltip-type-black {
    background-color: $geaui-primary-black;

    .geaui-tooltip-content, .geaui-tooltip-title {
      color: $tooltipTextColor;
    }
  }

  &.geaui-tooltip-type-orange {
    background-color: $geaui-radiant-orange-20;

    .geaui-tooltip-content, .geaui-tooltip-title {
      color: $tooltipTextColor;
    }
  }

  .geaui-tooltip-icon {
    padding-right: 10px;
    padding-top: 1.5px;
  }

  .geaui-tooltip-title {
    margin-bottom: 6px;
  }

  .geaui-tooltip-content {
    position: relative;
    top: 0;
  }
}
