

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: "GEA Inter Regular", serif;
  font-size: 16px;
}

.mat-expansion-indicator::after {
  height: 16px;
 width: 16px;
 background-image: url('assets/icons/16px_blue-chevron-down.svg');
 background-size: cover;
 border: none;
 border-width: 0 !important;
 transform: rotate(0) !important;
}
