.p-dropdown {
  background: $inputBg;
  border: $inputBorder;
  transition: $formElementTransition;
  border-radius: $borderRadius;
  font-weight: 500;

  &:not(.p-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.p-disabled).p-focus {
    //@include focused-input();
    border-color: $geaui-focused-outline;
  }

  &.p-dropdown-clearable {
    .p-dropdown-label {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }
  }

  .p-dropdown-label {
    background: transparent;
    border: 0 none;

    &.p-placeholder {
      color: $inputPlaceholderTextColor;
    }

    &:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-dropdown-trigger {
    background: transparent;
    color: $inputIconColor;
    width: 2rem;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  .p-dropdown-clear-icon {
    color: $inputIconColor;
    right: $inputGroupAddOnMinWidth;
  }
}

.p-datatable .p-datatable-tbody > tr > td:has(geaui-select-renderer) {
  padding: 0;
}


td geaui-select-renderer .gea-form-container-margin {
  margin-top: 0;
  margin-bottom: 0;

  .gea-select-field .p-dropdown {
    height: 52px;
    background: none;
    border: none;

    .p-inputtext {
      padding-top: 16px;
    }

    &.p-dropdown-open {
      border: 2px solid #0303B8;
      border-bottom: none;
    }
  }
}

.p-dropdown-panel {
  background: $inputOverlayBg;
  color: $inputListTextColor;
  border: $inputOverlayBorder;
  border-radius: $borderRadius;
  box-shadow: $inputOverlayShadow;

  .p-dropdown-header {
    padding: $inputListHeaderPadding;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background: $inputOverlayHeaderBg;
    margin: $inputListHeaderMargin;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;

    .p-dropdown-filter {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
      margin-right: -1 * (nth($inputPadding, 2) + $primeIconFontSize);
    }

    .p-dropdown-filter-icon {
      right: nth($inputPadding, 2);
      color: $inputIconColor;
    }
  }

  .p-dropdown-items {
    padding: $inputListPadding;
    position: relative;
    background-color: $geaui-grey-05;

    .p-dropdown-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;
      border-radius: $inputListItemBorderRadius;
      height: $inputListItemHeight;
      align-content: center;

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: $inputListItemTextHoverColor;
        background: $inputListItemHoverBg;
      }
    }

    .p-dropdown-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
    }

    .p-dropdown-item-group {
      margin: $submenuHeaderMargin;
      padding: $submenuHeaderPadding;
      color: $submenuHeaderTextColor;
      background: $submenuHeaderBg;
      font-weight: $submenuHeaderFontWeight;
    }
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  //@include invalid-input();
  border: 3px solid $geaui-tertiary-red-60;
}

.p-input-filled {
  .p-dropdown {
    background: $inputFilledBg;

    &:not(.p-disabled):hover {
      background-color: $inputFilledHoverBg;
    }

    &:not(.p-disabled).p-focus {
      background-color: $inputFilledFocusBg;

      .p-inputtext {
        background-color: transparent;
      }
    }
  }
}

/*   Custom Css Styles    */

.gea-select-field .p-dropdown {
  position: relative;
  align-items: center;
  width: round(100%, 1px);
  height: 48px;
}

.gea-select-field .p-dropdown-label.p-inputtext.p-placeholder {
  color: $geaui-pure-black;
  padding-top: 9px;
  padding-left: 16px;
  font-weight: 400;
}

.gea-select-field .p-dropdown-label.p-inputtext {
  color: $geaui-pure-black;
}

.gea-select-field .p-disabled .p-dropdown-label.p-inputtext.p-placeholder {
  color: $geaui-grey-01;
}

.gea-select-field.p-float-label > label {
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 400;
  top: 47%;
  height: 20px;
}

.gea-select-field .p-dropdown.p-inputwrapper-focus {
  border: 2px solid $geaui-primary;
}

.gea-select-field .isDropdownOpen .p-dropdown.p-inputwrapper-focus {
  border-bottom: none;
}

.gea-select-field .dropdown-upwards.isDropdownOpen .p-dropdown.p-inputwrapper-focus {
  border-top: none;
  border-bottom: 2px solid $geaui-primary;
}

.gea-select-field .p-dropdown.p-component.p-disabled {
  border-color: $geaui-grey-05;
}

.gea-select-label-disabled {
  color: $geaui-grey-01 !important;
  opacity: 0.8;
}

.gea-select-field .p-dropdown-panel,
.p-overlay .p-dropdown-panel,
.p-overlay .p-autocomplete-panel,
.p-overlay .p-multiselect-panel {
  overflow: auto;
  position: relative;
  border-left: 2px solid $geaui-primary;
  border-right: 2px solid $geaui-primary;
  border-bottom: 2px solid $geaui-primary;
  top: 0;
}

body > .p-overlay {
  width: 0;

  .p-autocomplete-panel {
    top: 24px;
  }

  .p-dropdown-panel {
    left: 0;
    width: 100%;
  }
}

geaui-select-v2 .gea-always-float-label > label {
  top: -14px !important;
  left: -7px !important;
  font-size: 14px !important;
}

.dropdown-upwards .p-dropdown-panel,
:has(.dropdown-upwards.isDropdownOpen) body > .p-overlay .p-dropdown-panel {
  border-top: 2px solid $geaui-primary;
  border-bottom: none;
  top: 0;
}

:has(.dropdown-upwards.isDropdownOpen) body > .p-overlay .p-multiselect-panel {
  border-top: 2px solid $geaui-primary;
  border-bottom: none;
  top: 0;
}

.gea-select-field .p-dropdown-panel,
body > .p-overlay .p-dropdown-panel,
body > .p-overlay .p-autocomplete-panel {
  .p-dropdown-items,
  .p-autocomplete-items {
    padding: 0;

    .p-focus {
      color: $geaui-vibrant-blue !important;
      background-color: $geaui-vibrant-blue-100 !important;
    }
  }

  .p-dropdown-items .p-dropdown-item {
    margin: 0;
    border: 0 none;
    color: $geaui-pure-black;
    background: transparent;
    transition: background-color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
    border-radius: 0;

    &:not(.p-disabled):hover {
      color: $geaui-primary;
      background-color: $geaui-vibrant-blue-100;
    }

    span {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
}

.gea-error-container-select {
  position: relative;
  display: flex;

  > p {
    position: absolute;
    top: 5px;
    left: 27px;
    font-size: 14px;
    margin: 0;
    color: $geaui-tertiary-red-80;
  }

  > .gea-error-icon-position {
    position: absolute;
    top: 5px;
    left: 2px;
  }
}

.gea-select-field.p-float-label > input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -14px !important;
  font-size: 14px;
  left: -7px;
}

.p-scroller {
	overflow-anchor: none;
}

.gea-select-field .p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 15px;
}

.gea-select-field .p-dropdown-items-wrapper::-webkit-scrollbar-track {
  background: $geaui-grey-05;
}

.gea-select-field .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  color: $geaui-primary;
  border-top: 2px solid $geaui-grey-05;
  border-right: 5px solid $geaui-grey-05;
  border-left: 5px solid $geaui-grey-05;
}

.gea-select-field .p-dropdown.p-inputwrapper .p-dropdown-label {
  border: none;
}

.gea-select-field .p-disabled .p-element.p-dropdown-label.p-inputtext {
  color: $geaui-grey-01;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 400;
}

.sidebar-dropdown {
  &.hide {
    display: none;
  }

  .p-dropdown {
    position: relative;
    align-items: center;
    width: round(100%, 1px);
    height: 49px;
    border-radius: 8px;
    border: none;
    background: var(--geaui-grey-04);
    padding: 16px;
    color: var(--geaui-black);

    &:not(.p-disabled):hover,
    &:not(.p-disabled).p-focus {
      border: none;
    }

    .p-dropdown-label {
      @include gea-font-heading(14);
      padding: 0;
    }

    .p-dropdown-panel {
      top: 4px;
      left: 0;
    }
  }

  .p-focus .p-inputtext:focus {
    border: none;
  }
}

geaui-sidebar-v2 .p-overlay .p-dropdown-panel {
  &.slim-view {
    top: 112px;
    left: 64px;

    .p-dropdown-items .p-dropdown-item {
      width: 176px;
    }
  }

  &.normal-view {
    top: 4px;
    left: 0;
  }

  overflow: auto;
  position: relative;
  border: 2px solid var(--geaui-grey-03);

  .p-dropdown-items {
    padding: 0;
    position: relative;

    .p-dropdown-item {
      @include gea-font-body(16);
      padding: 12px 16px;
      background: var(--geaui-pure-white);

      &:not(.p-highlight):not(.p-disabled):hover {
        background: var(--geaui-vibrant-blue-190);
        color: var(--geaui-vibrant-blue);
      }

      &.p-highlight {
        background: var(--geaui-vibrant-blue-190);
        color: var(--geaui-vibrant-blue);

        &:hover {
          background: var(--geaui-vibrant-blue-190);
        }
      }
    }
  }
}
