.geaui-chip-container {
  display: inline-block;
  .geaui-chip-readonly {
      display: flex;
      align-items: center;
      border-radius: 4px;
    &.geaui-chip-small {
      padding: 4px;
      gap: 4px;
    }
    &.geaui-chip-medium {
      padding: 8px;
      gap: 4px;
    }
    &.geaui-chip-type-default {
      background-color: $geaui-grey-04;
      color: $geaui-primary-black;
    }
    &.geaui-chip-type-error {
      background-color: $geaui-tertiary-red-30;
      color: $geaui-tertiary-red-80;
    }
    &.geaui-chip-type-warning {
      background-color: $geaui-radiant-orange-30;
      color: $geaui-radiant-orange;
    }
    &.geaui-chip-type-success {
      background-color: $geaui-active-green-60;
      color: $geaui-tertiary-green-80;
    }
    &.geaui-chip-type-information {
      background-color: $geaui-active-blue-60;
      color: $geaui-active-blue-120;
    }
    &.geaui-chip-type-progress {
      background-color: $geaui-tertiary-yellow-30;
      color: $geaui-radiant-orange-110;
    }
  }
}
