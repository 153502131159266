@use 'sass:math';

$additionalPadding: 130px;
$xl-desktop-min: 3840px;
$l-desktop-max: 3839px;
$l-desktop-min: 2560px;
$default-desktop-max: 2559px;
$default-desktop-min: 1920px;
$s-desktop-max: 1919px;
$s-desktop-min: 1440px;
$ml-tablet-max: 1439px;
$ml-tablet-min: 1024px;
$sm-tablet-max: 1023px;
$sm-tablet-min: 768px;
$ml-phone-max: 767px;
$ml-phone-min: 480px;
$sm-phone-max: 479px;

geaui-grid .gs-id-0 .grid-stack-placeholder {
    .placeholder-content {
      border-radius: 10px !important;
    }
}

@media (max-width: #{$sm-phone-max}) {
  $columns: 2;
  $widgetSize: calc(#{$sm-phone-max} / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }

  geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * #{$i});
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$ml-phone-min}) and (max-width: #{$ml-phone-max}) {
  $columns: 2;
  $widgetSize: calc(#{$ml-phone-min} / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }
  geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * #{$i});
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$sm-tablet-min}) and (max-width: #{$sm-tablet-max}) {
  $columns: 3;
  $widgetSize: calc((#{$sm-tablet-min} - #{$additionalPadding}) / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }
    geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * (#{$i}));
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$ml-tablet-min}) and (max-width: #{$ml-tablet-max}) {
  $columns: 4;
  $widgetSize: calc((#{$ml-tablet-min} - #{$additionalPadding}) / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }

  geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * (#{$i}));
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$s-desktop-min}) and (max-width: #{$s-desktop-max}) {
  $columns: 5;
  $widgetSize: calc((#{$s-desktop-min} - #{$additionalPadding}) / #{$columns});

    geaui-grid .gs-#{$columns} .grid-stack-item,
    geaui-grid .gs-#{$columns} .grid-stack-placeholder,
    geaui-grid .gs-id-0 .grid-stack-placeholder {
      width: #{$widgetSize};
      height: #{$widgetSize} !important;
    }

  geaui-grid .gs-#{$columns} .grid-stack-item {
      @for $i from 1 through $columns {
        &[gs-x='#{$i}'] {
          left: calc(#{$widgetSize} * #{$i});
        }
        &[gs-w='#{$i+1}'] {
          width: calc(#{$widgetSize} * (#{$i} + 1));
        }
        &[gs-y='#{$i}'] {
          top: calc(#{$widgetSize} * (#{$i}));
        }
        &[gs-h='#{$i+1}'] {
          height: calc(#{$widgetSize} * (#{$i} + 1));
        }
      }
  }
}

@media (min-width: #{$default-desktop-min}) and (max-width: #{$default-desktop-max}) {
  $columns: 5;
  $widgetSize: calc((#{$default-desktop-min} - #{$additionalPadding}) / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }
  geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * (#{$i}));
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$l-desktop-min}) and (max-width: #{$l-desktop-max}) {
  $columns: 6;
  $widgetSize: calc((#{$l-desktop-min} - #{$additionalPadding}) / #{$columns});

  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }

  geaui-grid .gs-#{$columns} .grid-stack-item {
    @for $i from 1 through $columns {
      &[gs-x='#{$i}'] {
        left: calc(#{$widgetSize} * #{$i});
      }
      &[gs-w='#{$i+1}'] {
        width: calc(#{$widgetSize} * (#{$i} + 1));
      }
      &[gs-y='#{$i}'] {
        top: calc(#{$widgetSize} * (#{$i}));
      }
      &[gs-h='#{$i+1}'] {
        height: calc(#{$widgetSize} * (#{$i} + 1));
      }
    }
  }
}

@media (min-width: #{$xl-desktop-min}) {
  $columns: 6;
  $widgetSize: calc(#{$xl-desktop-min} / 8);

  geaui-grid .grid-stack {
    margin-left: calc((100% / 2) - #{$widgetSize} * (#{$columns}/2) - 10px);
  }


  geaui-grid .gs-#{$columns} .grid-stack-item,
  geaui-grid .gs-#{$columns} .grid-stack-placeholder,
  geaui-grid .gs-id-0 .grid-stack-placeholder {
    width: #{$widgetSize};
    height: #{$widgetSize} !important;
  }

  geaui-grid .gs-#{$columns} .grid-stack-item {
      @for $i from 1 through $columns {
        &[gs-x='#{$i}'] {
          left: calc(#{$widgetSize} * #{$i});
        }
        &[gs-w='#{$i+1}'] {
          width: calc(#{$widgetSize} * (#{$i} + 1));
        }
        &[gs-y='#{$i}'] {
          top: calc(#{$widgetSize} * (#{$i}));
        }
        &[gs-h='#{$i+1}'] {
          height: calc(#{$widgetSize} * (#{$i} + 1));
        }
      }
  }
}
