@import '../colors';

$fontFamily: 'GEA Inter', 'GEA Sans', sans-serif;

// font-sizes in px
$headingSizes: 32, 24, 20, 16, 14;
$sublineSizes: 20, 16, 14;
$quoteSizes: 32, 18;
$bodySizes: 18, 16, 14, 12;
$descriptionSizes: 12, 10;
$buttonBoldSizes: 20, 16, 14;
$buttonRegularSizes: 16, 14;

// Headings
@mixin gea-font-heading($size) {
  font-size: #{$size}px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0;
  color: $geaui-primary-black;
}
@each $size in $headingSizes {
  .gea-font-heading-#{$size} {
    @include gea-font-heading($size);
  }
}

// Sublines
@mixin gea-font-subline($size) {
  font-size: #{$size}px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0;
  color: $geaui-primary-black;
}
@each $size in $sublineSizes {
  .gea-font-subline-#{$size} {
    @include gea-font-subline($size);
  }
}

// Quotes
@mixin gea-font-quote($size) {
  font-style: italic;
  font-weight: 500;
  font-size: #{$size}px;
  line-height: 130%;
  letter-spacing: 0;
  color: $geaui-primary-black;
}
@each $size in $quoteSizes {
  .gea-font-quote-#{$size} {
    @include gea-font-quote($size);
  }
}

// Body
@mixin gea-font-body($size) {
  font-size: #{$size}px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0;
  color: $geaui-primary-black;
}
@each $size in $bodySizes {
  .gea-font-body-#{$size} {
    @include gea-font-body($size);
  }
}

// Descriptions
@mixin gea-font-description($size) {
  font-weight: 500;
  font-size: #{$size}px;
  line-height: 140%;
  letter-spacing: 0;
  color: $geaui-primary-black;
}
@each $size in $descriptionSizes {
  .gea-font-description-#{$size} {
    @include gea-font-description($size);
  }
}

// Buttons
@mixin gea-font-button($size, $fontWeight: 600) {
  font-size: #{$size}px;
  @if $fontWeight == 500 or $fontWeight == 600 {
    font-weight: $fontWeight;
  } @else {
    font-weight: 600;
  }
  line-height: 140%;
  letter-spacing: 0;
  color: $geaui-primary-black
}
@each $size in $buttonBoldSizes {
  .gea-font-button-#{$size} {
    @include gea-font-button($size, 600);
  }
}

.gea-font-white {
  color: $geaui-pure-white;
}
