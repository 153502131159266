@use 'sass:math';

$inputSwitchLabelLeftMargin: 24px;

.p-inputswitch {
  width: $inputSwitchWidth;
  height: $inputSwitchHeight;

  .p-inputswitch-slider {
    background: $inputSwitchSliderOffBg;
    transition: $formElementTransition;
    border-radius: $inputSwitchBorderRadius;

    &:before {
      background: $inputSwitchHandleOffBg;
      width: $inputSwitchHandleWidth;
      height: $inputSwitchHandleHeight;
      left: $inputSwitchSliderPadding;
      margin-top: math.div(-1 * $inputSwitchHandleHeight, 2);
      border-radius: $inputSwitchHandleBorderRadius;
      transition-duration: $transitionDuration;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider:before {
      transform: translateX($inputSwitchHandleWidth - 8);
    }
  }

  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOffHoverBg;
    }
  }

  &.p-disabled {
    .p-inputswitch-slider {
      background: $geaui-grey-04;

      &:before {
        background: $geaui-grey-01;
      }
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOnBg;

      &:before {
        background: $inputSwitchHandleOnBg;
      }
    }

    &.p-disabled {
      .p-inputswitch-slider {
        background: $geaui-grey-01;

        &:before {
          background: $geaui-grey-04;
        }
      }
    }

    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: $inputSwitchSliderOnHoverBg;
      }
    }
  }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  @include invalid-input();
}

.geaui-toggle-container {
  height: 100%;

  .geaui-toggle-labeled-control {
    display: flex;
    align-items: center;
    gap: $inputSwitchLabelLeftMargin;

    label {
      &.disabled {
        color: $geaui-grey-01;
      }
    }

    p-inputswitch {
      height: 32px;
    }
  }

  &.flex {
    display: flex;
    flex: 1;

    .geaui-toggle-labeled-control {
      flex: 1;
      justify-content: space-between;
    }

  }

  &.geaui-pressed {
    .p-inputswitch:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: $geaui-primary-pressed;
      }

      &.p-inputswitch-checked {
        .p-inputswitch-slider {
          background: $geaui-secondary-pressed;
        }
      }
    }
  }
}
