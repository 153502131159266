/**
 * This file is provided directly by the design team. the sass variables are to be used only in the theme files.
 * For use in other scss files, please use the --geaui-... valiable that exposes the color globally to be used
 * with the var syntax - e.g. var(--geaui-vibrant-blue).
 */

// BASE COLOURS
$geaui-vibrant-blue: #0303B8;
$geaui-vibrant-blue-30: #4F4FCD;
$geaui-vibrant-blue-130: #020281;
$geaui-vibrant-blue-180: #CDCDF1;
$geaui-vibrant-blue-190: #E6E6F8;
$geaui-night-blue: #000F41;
$geaui-active-green: #1AFF80;
$geaui-pure-black: #000000;
$geaui-primary-black: #141414;
$geaui-secondary-black: #505050;
$geaui-grey-01: #8C8C8C;
$geaui-grey-02: #C8C8C8;
$geaui-grey-03: #DCDCDC;
$geaui-grey-04: #E6E6E6;
$geaui-grey-05: #F0F0F0;
$geaui-grey-06: #F5F5F5;
$geaui-pure-white: #FFFFFF;

// THEME COLOURS
$geaui-primary: $geaui-vibrant-blue;
$geaui-secondary: $geaui-night-blue;
$geaui-tertiary: $geaui-active-green;

// INTERACTION COLOURS
$geaui-button-default: $geaui-vibrant-blue;
$geaui-primary-hover: $geaui-vibrant-blue-30;
$geaui-primary-pressed: $geaui-vibrant-blue-130;
$geaui-secondary-hover: $geaui-vibrant-blue-190;
$geaui-secondary-pressed: $geaui-vibrant-blue-180;
$geaui-focused-outline: $geaui-night-blue;

// INFORMATION COLOURS
$geaui-energetic-pink: #FF80FF;
$geaui-active-blue: #1F9DFF;
$geaui-tertiary-green-60: #15BD5E;
$geaui-tertiary-yellow-60: #FFDD2D;
$geaui-radiant-orange-70: #FF740F;
$geaui-tertiary-red-60: #FC4B53;
$geaui-active-blue-50: #D5EDFF;
$geaui-night-blue-120: #00081F;
$geaui-energetic-pink-130: #BB23E0;
$geaui-active-blue-120: #145DE2;
$geaui-tertiary-green-80: #107C3C;
$geaui-radiant-orange-110: #AF7927;
$geaui-radiant-orange: #AF4A01;
$geaui-tertiary-red-80: #A61920;
$geaui-energetic-pink-60: #FFD0FF;
$geaui-energetic-pink-140: #701586;
$geaui-active-blue-60: #BFE3FF;
$geaui-vibrant-blue-120: #08257F;
$geaui-active-green-60: #DEFFEC;
$geaui-tertiary-green-110: #0B5E2E;
$geaui-tertiary-yellow-30: #FFF2B2;
$geaui-tertiary-yellow-110: #393204;
$geaui-radiant-orange-30: #FED3B3;
$geaui-radiant-orange-120: #393204;
$geaui-tertiary-red-30: #FFC8CB;
$geaui-tertiary-red-110: #340111;

// SPECTRUM COLOURS
$geaui-night-blue-130: #001664;
$geaui-vibrant-blue-140: #391EC7;
$geaui-vibrant-blue-110: #020A7D;
$geaui-vibrant-blue-100: rgba(3, 3, 184, 0.08);
$geaui-vibrant-blue-90: #0B2ECC;
$geaui-active-blue-130: #0E3ED4;
$geaui-active-blue-110: #197AEF;
$geaui-active-blue-95: #42ADFF;
$geaui-active-blue-90: #48AFFF;
$geaui-active-blue-85: #66BCFF;
$geaui-active-blue-80: #6BBEFF;
$geaui-active-blue-75: #8CCDFF;
$geaui-active-blue-70: #94D0FF;
$geaui-active-blue-65: #B0DDFF;
$geaui-grey-07: #282828;
$geaui-grey-08: #3C3C3C;
$geaui-grey-09: #646464;
$geaui-grey-10: #787878;
$geaui-grey-11: #A0A0A0;
$geaui-grey-12: #B4B4B4;
$geaui-grey-13: #FAFBFC;
$geaui-tertiary-red: #57021D;
$geaui-tertiary-red-90: #750E13;
$geaui-tertiary-red-70: #DB1E28;
$geaui-tertiary-red-50: #FF767C;
$geaui-tertiary-red-40: #FFA4A9;
$geaui-tertiary-green: #0A3D1B;
$geaui-tertiary-green-90: #0D5C2B;
$geaui-tertiary-green-70: #129C4D;
$geaui-tertiary-green-50: #18DD6E;
$geaui-tertiary-green-40: #53F99B;
$geaui-tertiary-yellow: #5F5407;
$geaui-tertiary-yellow-90: #917D05;
$geaui-tertiary-yellow-80: #C5A703;
$geaui-tertiary-yellow-70: #F2CB00;
$geaui-tertiary-yellow-50: #FFE45B;
$geaui-tertiary-yellow-40: #FFEA7F;
$geaui-radiant-orange-90: #D65A00;
$geaui-radiant-orange-80: #F36600;
$geaui-radiant-orange-60: #FF8832;
$geaui-radiant-orange-50: #FBA05E;
$geaui-radiant-orange-40: #FEB580;
$geaui-radiant-orange-20: #FAB301;
$geaui-energetic-pink-120: #E842E8;
$geaui-energetic-pink-110: #FF57FF;
$geaui-energetic-pink-90: #FF8DFF;
$geaui-energetic-pink-80: #FFA3FF;
$geaui-energetic-pink-70: #FFB0FF;
$geaui-active-green-90: #4AFF9B;
$geaui-active-green-80: #7DFFB7;
$geaui-active-green-70: #C8FFE0;
$geaui-active-blue-55: #1976D2;
$geaui-active-blue-45: #1E88E5;
$geaui-vibrant-blue-brightness-50: rgba(3, 3, 184, 0.5);
$geaui-pure-white-brightness-30: rgba(255, 255, 255, 0.3);

// GRADIENT
$geaui-gradient-activegreen: linear-gradient(180deg, $geaui-night-blue-130 8.33%, $geaui-active-green 100%);
$geaui-gradient-dailypink: linear-gradient(225.69deg, $geaui-energetic-pink 0%, $geaui-energetic-pink 15.63%, $geaui-vibrant-blue-140 68.23%, $geaui-vibrant-blue 100%);

$geaui-gradient-energetic-pink: linear-gradient(153deg, #C239E3 16.67%, #EBBDF6 100%);
$geaui-gradient-active-blue: linear-gradient(153deg, #3087F1 16.67%, #BAD7FA 100%);
$geaui-gradient-green: linear-gradient(153deg, #1A7573 16.67%, #E6F0EF 100%);
$geaui-gradient-vibrant-blue: linear-gradient(153deg, $geaui-vibrant-blue 16.67%, #B3B3EA 100%);
$geaui-gradient-midnight-blue: linear-gradient(155deg, #1A2754 15.99%, #B3B7C6 99.04%);
$geaui-gradient-pink-blue: linear-gradient($geaui-energetic-pink, $geaui-vibrant-blue-140, $geaui-vibrant-blue);

$geaui-background-vibrant-blue: #9A9AE3;
$geaui-background-green: #80B3B1;
$geaui-background-pink: #E4A7F3;
$geaui-background-grey: #999FB3;
$geaui-background-active-blue: #A3CAF9;





// eCHART COLOURS
$geaui-night-blue-100: #000C34;

.ortus-light-theme {
  :root, * {
    --geaui-primary: #{$geaui-vibrant-blue};
    --geaui-secondary: #{$geaui-night-blue};
    --geaui-tertiary: #{$geaui-active-green};
    --geaui-button-default: #{$geaui-vibrant-blue};
    --geaui-primary-hover: #{$geaui-vibrant-blue-30};
    --geaui-primary-pressed: #{$geaui-vibrant-blue-130};
    --geaui-secondary-hover: #{$geaui-vibrant-blue-190};
    --geaui-secondary-pressed: #{$geaui-vibrant-blue-180};
    --geaui-focused-outline: #{$geaui-night-blue};
    --geaui-focussed: #{$geaui-vibrant-blue};
    --geaui-vibrant-blue: #{$geaui-vibrant-blue};
    --geaui-vibrant-blue-30: #{$geaui-vibrant-blue-30};
    --geaui-vibrant-blue-130: #{$geaui-vibrant-blue-130};
    --geaui-vibrant-blue-180: #{$geaui-vibrant-blue-180};
    --geaui-vibrant-blue-190: #{$geaui-vibrant-blue-190};
    --geaui-night-blue: #{$geaui-night-blue};
    --geaui-active-green: #{$geaui-active-green};
    --geaui-pure-black: #{$geaui-pure-black};
    --geaui-primary-black: #{$geaui-primary-black};
    --geaui-secondary-black: #{$geaui-secondary-black};
    --geaui-grey-01: #{$geaui-grey-01};
    --geaui-grey-02: #{$geaui-grey-02};
    --geaui-grey-03: #{$geaui-grey-03};
    --geaui-grey-04: #{$geaui-grey-04};
    --geaui-grey-05: #{$geaui-grey-05};
    --geaui-grey-06: #{$geaui-grey-06};
    --geaui-pure-white: #{$geaui-pure-white};
    --geaui-energetic-pink: #{$geaui-energetic-pink};
    --geaui-active-blue: #{$geaui-active-blue};
    --geaui-tertiary-green-60: #{$geaui-tertiary-green-60};
    --geaui-tertiary-yellow-60: #{$geaui-tertiary-yellow-60};
    --geaui-radiant-orange-70: #{$geaui-radiant-orange-70};
    --geaui-tertiary-red-60: #{$geaui-tertiary-red-60};
    --geaui-active-blue-50: #{$geaui-active-blue-50};
    --geaui-night-blue-120: #{$geaui-night-blue-120};
    --geaui-energetic-pink-130: #{$geaui-energetic-pink-130};
    --geaui-active-blue-120: #{$geaui-active-blue-120};
    --geaui-tertiary-green-80: #{$geaui-tertiary-green-80};
    --geaui-radiant-orange-110: #{$geaui-radiant-orange-110};
    --geaui-radiant-orange: #{$geaui-radiant-orange};
    --geaui-tertiary-red-80: #{$geaui-tertiary-red-80};
    --geaui-energetic-pink-60: #{$geaui-energetic-pink-60};
    --geaui-energetic-pink-140: #{$geaui-energetic-pink-140};
    --geaui-active-blue-60: #{$geaui-active-blue-60};
    --geaui-vibrant-blue-120: #{$geaui-vibrant-blue-120};
    --geaui-active-green-60: #{$geaui-active-green-60};
    --geaui-tertiary-green-110: #{$geaui-tertiary-green-110};
    --geaui-tertiary-yellow-30: #{$geaui-tertiary-yellow-30};
    --geaui-tertiary-yellow-110: #{$geaui-tertiary-yellow-110};
    --geaui-radiant-orange-30: #{$geaui-radiant-orange-30};
    --geaui-radiant-orange-120: #{$geaui-radiant-orange-120};
    --geaui-tertiary-red-30: #{$geaui-tertiary-red-30};
    --geaui-tertiary-red-110: #{$geaui-tertiary-red-110};
    --geaui-night-blue-130: #{$geaui-night-blue-130};
    --geaui-vibrant-blue-140: #{$geaui-vibrant-blue-140};
    --geaui-vibrant-blue-110: #{$geaui-vibrant-blue-110};
    --geaui-vibrant-blue-90: #{$geaui-vibrant-blue-90};
    --geaui-active-blue-130: #{$geaui-active-blue-130};
    --geaui-active-blue-110: #{$geaui-active-blue-110};
    --geaui-active-blue-95: #{$geaui-active-blue-95};
    --geaui-active-blue-90: #{$geaui-active-blue-90};
    --geaui-active-blue-85: #{$geaui-active-blue-85};
    --geaui-active-blue-80: #{$geaui-active-blue-80};
    --geaui-active-blue-75: #{$geaui-active-blue-75};
    --geaui-active-blue-70: #{$geaui-active-blue-70};
    --geaui-active-blue-65: #{$geaui-active-blue-65};
    --geaui-grey-07: #{$geaui-grey-07};
    --geaui-grey-08: #{$geaui-grey-08};
    --geaui-grey-09: #{$geaui-grey-09};
    --geaui-grey-10: #{$geaui-grey-10};
    --geaui-grey-11: #{$geaui-grey-11};
    --geaui-grey-12: #{$geaui-grey-12};
    --geaui-grey-13: #{$geaui-grey-13};
    --geaui-tertiary-red: #{$geaui-tertiary-red};
    --geaui-tertiary-red-90: #{$geaui-tertiary-red-90};
    --geaui-tertiary-red-70: #{$geaui-tertiary-red-70};
    --geaui-tertiary-red-50: #{$geaui-tertiary-red-50};
    --geaui-tertiary-red-40: #{$geaui-tertiary-red-40};
    --geaui-tertiary-green: #{$geaui-tertiary-green};
    --geaui-tertiary-green-90: #{$geaui-tertiary-green-90};
    --geaui-tertiary-green-70: #{$geaui-tertiary-green-70};
    --geaui-tertiary-green-50: #{$geaui-tertiary-green-50};
    --geaui-tertiary-green-40: #{$geaui-tertiary-green-40};
    --geaui-tertiary-yellow: #{$geaui-tertiary-yellow};
    --geaui-tertiary-yellow-90: #{$geaui-tertiary-yellow-90};
    --geaui-tertiary-yellow-80: #{$geaui-tertiary-yellow-80};
    --geaui-tertiary-yellow-70: #{$geaui-tertiary-yellow-70};
    --geaui-tertiary-yellow-50: #{$geaui-tertiary-yellow-50};
    --geaui-tertiary-yellow-40: #{$geaui-tertiary-yellow-40};
    --geaui-radiant-orange-90: #{$geaui-radiant-orange-90};
    --geaui-radiant-orange-80: #{$geaui-radiant-orange-80};
    --geaui-radiant-orange-60: #{$geaui-radiant-orange-60};
    --geaui-radiant-orange-50: #{$geaui-radiant-orange-50};
    --geaui-radiant-orange-40: #{$geaui-radiant-orange-40};
    --geaui-radiant-orange-20: #{$geaui-radiant-orange-20};
    --geaui-energetic-pink-120: #{$geaui-energetic-pink-120};
    --geaui-energetic-pink-110: #{$geaui-energetic-pink-110};
    --geaui-energetic-pink-90: #{$geaui-energetic-pink-90};
    --geaui-energetic-pink-80: #{$geaui-energetic-pink-80};
    --geaui-energetic-pink-70: #{$geaui-energetic-pink-70};
    --geaui-active-green-90: #{$geaui-active-green-90};
    --geaui-active-green-80: #{$geaui-active-green-80};
    --geaui-active-green-70: #{$geaui-active-green-70};
    --geaui-gradient-activegreen: #{$geaui-gradient-activegreen};
    --geaui-gradient-dailypink: #{$geaui-gradient-dailypink};
    --geaui-night-blue-100: #{$geaui-night-blue-100};
    --geaui-pure-white-brightness-30: #{$geaui-pure-white-brightness-30};
    --geaui-gradient-energetic-pink: #{$geaui-gradient-energetic-pink};
    --geaui-gradient-active-blue : #{$geaui-gradient-active-blue};
    --geaui-gradient-green : #{$geaui-gradient-green};
    --geaui-gradient-vibrant-blue : #{$geaui-gradient-vibrant-blue};
    --geaui-gradient-midnight-blue : #{$geaui-gradient-midnight-blue};
    --geaui-gradient-pink-blue : #{$geaui-gradient-pink-blue};
    --geaui-background-vibrant-blue : #{$geaui-background-vibrant-blue};
    --geaui-background-green : #{$geaui-background-green};
    --geaui-background-pink : #{$geaui-background-pink};
    --geaui-background-grey : #{$geaui-background-grey};
    --geaui-background-active-blue : #{$geaui-background-active-blue};
  }
}
