.p-button.p-button-link {
  padding: 0 !important;
  height: 22px;
}

// primary link
.p-button.p-button-link.p-button-primary {
  padding: 0;
  color: $geaui-button-default;
  &:disabled {
    color: $geaui-grey-01;
  }
  &:enabled:hover {
    color: $geaui-primary-hover;
  }
  .icon-v2-stroke-white path {
    stroke: $geaui-pure-white;
  }
  .icon-v2-fill-white path {
    fill: $geaui-pure-white;
  }
  &:enabled:active {
    color: $geaui-primary-pressed;
  }
  &:hover:not(:active) {
    .icon-v2-hover-stroke-blue path {
      stroke: $geaui-primary-hover;
    }
    .icon-v2-hover-fill-blue path {
      fill: $geaui-primary-hover;
    }
  }
  &:hover:active {
    .icon-v2-hover-stroke-blue path {
      stroke: $geaui-primary-pressed;
    }
    .icon-v2-hover-fill-blue path {
      fill: $geaui-primary-pressed;
    }
  }
}

// secondary link
.p-button.p-button-link.p-button-secondary {
  color: $geaui-primary-black;
  &:disabled {
    color: $geaui-grey-01;
  }
  &:enabled:hover {
    color: $geaui-primary-black;
  }
  .icon-v2-stroke-white path {
    stroke: $geaui-pure-white;
  }
  .icon-v2-fill-white path {
    fill: $geaui-pure-white;
  }
  &:enabled:active {
    color: $geaui-primary-black;
  }
  &:hover:not(:active) {
    .icon-v2-hover-stroke-grey path {
      stroke: $geaui-secondary-black;
    }
    .icon-v2-hover-fill-grey path {
      fill: $geaui-secondary-black;
    }
  }
}

// white-transparent link
.p-button.p-button-link.p-button-white-transparent {
  color: $geaui-pure-white;
  &:disabled {
    color: $geaui-grey-01;
  }
  &:enabled:hover {
    color: $geaui-pure-white;
  }
  .icon-v2-stroke-white path {
    stroke: $geaui-pure-white;
  }
  .icon-v2-fill-white path {
    fill: $geaui-pure-white;
  }
  &:enabled:active {
    color: $geaui-pure-white;
  }
  &:hover:not(:active) {
    .icon-v2-hover-stroke-grey path {
      stroke: $geaui-pure-white;
    }
    .icon-v2-hover-fill-grey path {
      fill: $geaui-pure-white;
    }
  }
}
