@import 'variables';

/**
 * a wider overlay for small screens
 */
@media only screen and (max-width: $gea-screen-md) {
  ::ng-deep {
    .cdk-overlay-pane {
      max-width: 90vw !important;
    }
  }
}
