.p-radiobutton {
  width: $radiobuttonWidth;
  height: $radiobuttonHeight;

  .p-radiobutton-box {
    border: $radiobuttonBorder;
    background: $inputBg;
    width: $radiobuttonWidth;
    height: $radiobuttonHeight;
    color: $textColor;
    border-radius: 50%;
    transition: $formElementTransition;

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $geaui-vibrant-blue-brightness-50;
      background: $geaui-secondary-hover;
    }

    &:not(.p-disabled):not(.p-highlight):focus {
      border-color: $geaui-focused-outline;
    }


    &:not(.p-disabled):not(.p-highlight):active {
      border-color: $geaui-primary;
      background: $geaui-secondary-hover;
    }

    &:not(.p-disabled).p-focus {
      //@include focused-input();
      border-color: $geaui-primary;
    }

    .p-radiobutton-icon {
      width: $radiobuttonIconSize;
      height: $radiobuttonIconSize;
      transition-duration: $transitionDuration;
      background-color: $geaui-button-default;
      border-color: $geaui-pure-white;
    }


    &.p-highlight {
      border-color: $radiobuttonActiveBorderColor;
      background: $geaui-pure-white;

      &:not(.p-disabled):hover {
        border-color: $geaui-primary-hover;
        background: $geaui-secondary-hover;
        color: $radiobuttonIconActiveHoverColor;
      }
    }
  }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  @include invalid-input();
}

.p-input-filled {
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: $inputFilledBg;

      &:not(.p-disabled):hover {
        background-color: $geaui-secondary-hover;
      }

      &.p-highlight {
        background: $radiobuttonActiveBg;

        &:not(.p-disabled):hover {
          background: $radiobuttonActiveHoverBg;
        }
      }
    }
  }
}

.p-radiobutton-label {
  margin-left: $inlineSpacing;
}

@if ($highlightBg == $radiobuttonActiveBg) {
  .p-highlight {
    .p-radiobutton {
      .p-radiobutton-box {
        border-color: $radiobuttonIconActiveColor;
      }
    }
  }
}

// Custom Styles
.gea-radio-group-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  label {
    bottom: 2px;
    padding-left: 16px;
    display: inline-block;
    position: relative;
  }

  .gea-radio-group {
    .gea-disabled-radio-button-container {
      display: inline-block;
      width: $radiobuttonWidth;
      height: $radiobuttonHeight;
      border: 2px solid $geaui-grey-04;
      background: $geaui-grey-05;
      border-radius: 50%;
      cursor: not-allowed;
      position: relative;
      float: left;
      clear: both;

      span {
        position: absolute;
        display: block;
        height: $radiobuttonIconSize;
        width: $radiobuttonIconSize;
        border-radius: 20px;
        background-color: $geaui-grey-01;
        margin: 0;
        top: 3px;
        left: 3px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        background: #1d1d1d;
        color: #00BCD4;
        opacity: 0;
        cursor: not-allowed;
      }
    }
    label.gea-disabled-radio-button-label {
      top: 2px;
      color: $geaui-grey-01;
    }
  }
}

.gea-radio-group-container.column {
  flex-direction: column;
  min-height: 100px;

  .gea-radio-group {
    margin-top: 16px;
  }
}

.gea-radio-group-container.row {
  flex-direction: row;
  min-width: 100px;

  .gea-radio-group {
    margin-right: 16px;
  }
}

.gea-radio-group .gea-pressed {
  .p-radiobutton-icon {
    background-color: $geaui-primary-pressed;
  }
  .p-radiobutton .p-radiobutton-box {
    border-color: $geaui-primary-pressed !important;
    background: $geaui-secondary-pressed !important;
  }
}
