@import "color";

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--geaui-light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--geaui-vibrant-blue);
}

.mat-select-panel::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.mat-select-panel::-webkit-scrollbar-track {
  background: var(--geaui-light-gray);
}

.mat-select-panel::-webkit-scrollbar-thumb {
  background: var(--geaui-vibrant-blue);
  border: 5px solid var(--geaui-light-gray);
}

.mat-autocomplete-panel::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.mat-autocomplete-panel::-webkit-scrollbar-track {
  background-color: var(--geaui-light-gray);
}

.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: var(--geaui-vibrant-blue);
  border: 5px solid var(--geaui-light-gray);
}

.p-datatable-wrapper::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-clip: content-box;
}

.p-treetable-scrollable-body::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.p-treetable-scrollable-body::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-clip: content-box;
}
