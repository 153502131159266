.gea-tab-menu-container {
  .p-tabmenu {
    .p-tabmenu-nav {
      background: $tabviewNavBg;
      border-width: $tabviewNavBorderWidth;

      .gea-pressed  {
        &.p-tabmenuitem.p-highlight, &.p-tabmenuitem:not(.p-highlight):not(p-disabled):hover {
          .p-menuitem-link {
            border-color: $geaui-focused-outline;
          }
        }
      }

      .p-menuitem-badge {
        @include menuitem-badge();
      }

      .p-tabmenuitem {
        padding: 10px 0px;
        margin-right: 30px;
        cursor: pointer;
        .p-menuitem-link {
          border: $tabviewHeaderBorder;
          border-width: $tabviewHeaderBorderWidth;
          border-color: $tabviewHeaderBorderColor;
          background: $tabviewHeaderBg;
          color: $tabviewHeaderTextColor;
          padding: 12px 24px;
          font-weight: $tabviewHeaderFontWeight;
          border-top-right-radius: $borderRadius;
          border-top-left-radius: $borderRadius;
          transition: $listItemTransition;
          margin: $tabviewHeaderMargin;

          .p-menuitem-text {
            font-size: 16px;
            line-height: 140%;
            font-weight: 600;
            letter-spacing: 0;
          }
          .p-menuitem-icon {
            margin-right: $inlineSpacing;
          }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          .p-menuitem-link {
            background: $tabviewHeaderHoverBg;
            border-color: $tabviewHeaderHoverBorderColor;
            color: $tabviewHeaderTextHoverColor;
          }
        }

        &.p-highlight {
          .p-menuitem-link {
            background: $tabviewHeaderActiveBg;
            border-color: $tabviewHeaderActiveBorderColor;
            color: $geaui-primary;
          }
        }

        &.p-disabled {
          color: var(--geaui-grey-01);
        }
      }
    }

    .p-tabmenu-left-icon {
      margin-right: $inlineSpacing;
    }

    .p-tabmenu-right-icon {
      margin-left: $inlineSpacing;
    }

    .p-tabmenu-nav-btn.p-link {
      background: $tabviewHeaderActiveBg;
      color: $tabviewHeaderTextActiveColor;
      width: $buttonIconOnlyWidth;
      box-shadow: $raisedButtonShadow;
      border-radius: 0;
    }
  }
}
.gea-tab-menu-container.gea-tab-menu-type-filled {
  .p-tabmenu {
    .p-tabmenu-nav {
      border: 0;
      border-width: unset;

      .gea-pressed  {
        &.p-tabmenuitem.p-highlight, &.p-tabmenuitem:not(.p-highlight):not(p-disabled):hover {
          .p-menuitem-link {
            background-color: $geaui-secondary-pressed;
            color: $geaui-button-default;
          }
        }
      }
      .p-tabmenuitem {

        &:not(.p-highlight):not(.p-disabled):hover {
          .p-menuitem-link {
            background: $geaui-secondary-hover;
            color: $geaui-button-default;
          }
        }

        &.p-highlight {
          .p-menuitem-link {
            background: $geaui-button-default;
            color: $geaui-pure-white;
          }
        }
      }
    }
  }
}
.gea-tab-menu-container.gea-tab-menu-size-small {
  .p-tabmenu {
    .p-tabmenu-nav {
      .p-tabmenuitem {
        .p-menuitem-link {
          padding: 8px 16px;
        }
        .p-menuitem-text {
          font-size: 14px;
        }
      }
    }
  }
}

.gea-tab-menu-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight  {
  font-Weight: 600;
  color: $geaui-primary;
  border-bottom: 2px solid $geaui-primary;
}

.gea-tab-menu-container.gea-tab-menu-type-filled .p-tabmenu .p-tabmenu-nav .p-tabmenuitem{
  padding: 10px
}

.gea-tab-menu-container.gea-tab-menu-type-filled .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight  {
  font-Weight: 600;
  color: $geaui-pure-white;
  background-color: $geaui-primary;
}
