.gea-form-container-margin  {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
}


geaui-sidebar-v2 geaui-search-bar-v2 .gea-form-container-margin {
  margin: 0;
}
