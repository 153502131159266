:root {
  .geaui-grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 15px 20px;
    margin: 0 80px;
    // apply this class additionally to the geaui-grid class if the grid is used next to a sidebar
    &.geaui-sidebar-grid {
      margin: 0 40px;
    }

    &.geaui-form-grid {
      margin: 0 20px;
    }

    // apply this class additionally to the geaui-grid class if the grid contains a table to allow to scroll within the table
    &.geaui-scrollable-table {
      grid-auto-rows: minmax(40px, max-content);
    }
  }

  // sub grid can be used to create a nested grid. It comes witout the margins of the regular grid.
  .geaui-sub-grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 15px 20px;

    // apply this class additionally to the geaui-grid class if the grid contains a table to allow to scroll within the table
    &.geaui-scrollable-table {
      grid-auto-rows: minmax(40px, max-content);
    }
  }

  // medium desktop (1920px - 2559px) is the baseline config and can be overwritten for the other view points
  .geaui-col-desktop-md-0 {
    display: none;
  }

  @for $i from 1 through 12 {
    .geaui-col-desktop-md-#{$i} {
      grid-column-end: span #{$i};
    }
  }

  @for $i from 1 through 12 {
    .geaui-col-desktop-md-start-#{$i} {
      grid-column-start: #{$i};
    }
  }

  @media (max-width: 479px) {
    @for $i from 1 through 4 {
      .geaui-col-phone-sm-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (min-width: 3840px) {
    .geaui-grid {
      grid-template-columns: repeat(24, minmax(0, 1fr));
    }
    .geaui-sub-grid {
      grid-template-columns: repeat(24, minmax(0, 1fr));
    }
    .geaui-col-desktop-xl-0 {
      display: none;
    }
    @for $i from 1 through 24 {
      .geaui-col-desktop-xl-#{$i} {
        grid-column-end: span #{$i};
      }
    }

    @for $i from 1 through 24 {
      .geaui-col-desktop-xl-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (max-width: 3839px) and (min-width: 2560px) {
    .geaui-grid {
      grid-template-columns: repeat(12, 128px);
      justify-content: center;
      margin: 0;

      &.geaui-sidebar-grid {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        justify-content: unset;
        margin: 0 40px;
      }
    }
    .geaui-sub-grid {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .geaui-col-desktop-lg-0 {
      display: none;
    }
    @for $i from 1 through 12 {
      .geaui-col-desktop-lg-#{$i} {
        grid-column-end: span #{$i};
      }
    }

    @for $i from 1 through 12 {
      .geaui-col-desktop-lg-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (max-width: 1919px) and (min-width: 1440px) {
    .geaui-col-desktop-sm-0 {
      display: none;
    }

    @for $i from 1 through 12 {
      .geaui-col-desktop-sm-#{$i} {
        grid-column-end: span #{$i};
      }
    }

    @for $i from 1 through 12 {
      .geaui-col-desktop-sm-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (max-width: 1439px) and (min-width: 1024px) {
    .geaui-col-tablet-lg-0 {
      display: none;
    }
    @for $i from 1 through 12 {
      .geaui-col-tablet-lg-#{$i} {
        grid-column-end: span #{$i};
      }
    }

    @for $i from 1 through 12 {
      .geaui-col-tablet-lg-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    .geaui-col-tablet-sm-0 {
      display: none;
    }
    @for $i from 1 through 12 {
      .geaui-col-tablet-sm-#{$i} {
        grid-column-end: span #{$i};
      }
    }

    @for $i from 1 through 12 {
      .geaui-col-tablet-sm-start-#{$i} {
        grid-column-start: #{$i};
      }
    }
  }

  @media (max-width: 767px) and (min-width: 480px) {
    .geaui-grid {
      grid-template-columns: repeat(8, minmax(0, 1fr));
      margin: 0 40px;
    }
    .geaui-sub-grid {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .geaui-col-phone-lg-0 {
      display: none;
    }

    @for $i from 1 through 8 {
      .geaui-col-phone-lg-#{$i} {
        grid-column-end: span #{$i};
      }
    }
  }

  @media (max-width: 479px) and (min-width: 360px) {
    .geaui-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 10px;
      margin: 0 20px;
    }
    .geaui-sub-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 10px;
    }
    .geaui-col-phone-sm-0 {
      display: none;
    }
    @for $i from 1 through 4 {
      .geaui-col-phone-sm-#{$i} {
        grid-column-end: span #{$i};
      }
    }
  }


  @for $i from 1 through 12 {
    .geaui-row-desktop-md-start-#{$i} {
      grid-row-start: #{$i};
    }
  }

  @for $i from 1 through 12 {
    .geaui-row-desktop-md-#{$i} {
      grid-row-end: span #{$i};
    }
  }

  @for $i from 1 through 12 {
    .geaui-col-desktop-md-start-right-#{$i} {
      grid-column-start: -#{$i};
    }
  }
}
