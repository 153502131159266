@import './colors';

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

// GEA specific
@import './variables/_typograpy';

$gea-tablet-portrait: 768px;

:root {
    --font-family: 'GEA Inter', 'GEA Sans', sans-serif;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    // required by primeNG
    --maskbg: #{$maskBg};
    color-scheme: light;
}
