.p-divider {
  height: 2px;

  .p-divider-content {
    background-color: $panelContentBg;
  }

  &.p-divider-horizontal {
    &:before {
      border-top-color: $dividerColor;
      border-top-width: $dividerSize
    }

    .p-divider-content {
      padding: 0 $inlineSpacing;
    }
  }

  &.p-divider-vertical {
    &:before {
      border-left-color: $dividerColor;
      border-left-width: $dividerSize
    }

    .p-divider-content {
      padding: $inlineSpacing 0;
    }
  }
}