.p-accordion {
  .p-accordion-header {
    font-family: 'GEA Inter', 'GEA Sans', serif;
    @include gea-font-heading(16);
    .p-accordion-header-link {
      padding: $accordionHeaderPadding;
      border: 2px solid $geaui-grey-03;
      color: $accordionHeaderTextColor;
      background: $geaui-pure-white;
      font-weight: $accordionHeaderFontWeight;
      border-radius: $borderRadius;
      transition: $listItemTransition;
      border-right: none;
      border-left: none;
      margin: 0;
      height: 72px;
      border-top: none;
      .p-accordion-toggle-icon {
        margin-right: $inlineSpacing;
        position: absolute;
        right: 10px;
        font-size: 10px;

        .p-icon {
          width: 0.7rem;
          height: 0.7rem;
          transform: rotate(-270deg);
          color: $geaui-pure-black;
        }
      }
    }

    &.p-disabled {
      .p-accordion-header-link {
        border-bottom-color: $geaui-grey-03;
        color: $geaui-grey-02;
      }
    }

    &.p-disabled {
      .p-accordion-header-link {
        &:focus-visible {
          //@include focused();
          padding: 14px;
          border: 2px solid $geaui-focused-outline;
        }
        .p-icon {
          color: $geaui-grey-03;
        }
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: $geaui-primary;
        color: $accordionHeaderTextActiveColor;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .p-icon {
          width: 0.7rem;
          height: 0.7rem;
          transform: rotate(180deg);
          color: $geaui-primary;
        }
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        &:focus-visible {
          border-top: none;
          border-right: none;
          border-left: none;
        }
      }
    }
  }

  .p-accordion-content {
    padding: 1rem 1rem 1rem 1.5rem;
    background: $accordionContentBg;
    color: $accordionContentTextColor;
  }

  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: $accordionSpacing;
    }

    @if $accordionSpacing == 0 {
      .p-accordion-header {
        .p-accordion-header-link {
          border-radius: 0;
        }
      }

      .p-accordion-content {
        border-radius: 0;
      }

      &:not(:first-child) {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-accordion-header-link {
              border-top: 0 none;
            }
          }
        }
      }

      &:first-child {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top-right-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
          }
        }
      }

      &:last-child {
        .p-accordion-header:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
          }
        }

        .p-accordion-content {
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }
  }
}

.p-accordion.blue {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: $accordionHeaderPadding;
      border: 2px solid $geaui-pure-black;
      color: $geaui-pure-white;
      background: $geaui-primary;
      font-weight: $accordionHeaderFontWeight;
      transition: $listItemTransition;
      border-top: none;
      border-right: none;
      border-left: none;
      margin: 0;

      .p-icon {
        color: $geaui-pure-white;
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        color: $geaui-pure-white;

        .p-icon {
          width: 0.7rem;
          height: 0.7rem;
          transform: rotate(180deg);
          color: $geaui-pure-white;
        }
      }
    }
  }
}

// Disable top border on mobile devices
@media (max-width: 767px) and (min-width: 320px) {
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        border-top-style: none;
      }
    }
  }
}
