.p-breadcrumb {
  background: $breadcrumbBg;
  padding: $breadcrumbPadding;
  ul {
    li {
      .p-menuitem-link {
        transition: $listItemTransition;
        border-radius: $borderRadius;

        &:focus {
          @include focused();
        }

        .p-menuitem-text {
          color: $breadcrumbItemTextColor;
        }

        .p-menuitem-icon {
          color: $breadcrumbItemIconColor;
        }

        &:hover .p-menuitem-text {
          color: $geaui-primary-hover;
        }
      }

      &.p-breadcrumb-chevron {
        margin: 4px $inlineSpacing 0 $inlineSpacing;
        color: $breadcrumbSeparatorColor;
        transform: scale(0.75);
      }

      &:last-child {
        .p-menuitem-text {
          color: $breadcrumbLastItemTextColor;
        }

        .p-menuitem-icon {
          color: $breadcrumbLastItemIconColor;
        }
      }
    }
  }

  .activeBreadcrumbItem {
    .p-menuitem-link .p-menuitem-text {
      font-weight: 600;
      color: var(--geaui-vibrant-blue);
    }
  }

  .p-menuitem-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0 16px;
    cursor: pointer;
    &:hover .p-menuitem-text {
      color: $geaui-primary-hover;
    }
    .p-menuitem-text{
      color: $geaui-pure-black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include gea-font-heading(16)
    }
  }
  .p-menuitem-separator svg {
    height: 12px;
    width: 12px;
  }
  .p-breadcrumb-home .p-menuitem-icon  {
    display: none;
  }
}

.main-breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p-breadcrumb {
    overflow: hidden;
    .breadcrumb-item-long {
      overflow: hidden;
      min-width: 182px;
    }
  }
}

.folder-icon {
  margin-right: 8px;
}

.hidden-breadcrumbs-container {
  position: relative;
  z-index: 10;

  .hidden-breadcrumbs {
    border: $breadcrumbBorder;
    border-radius: $borderRadius;
    display: inline-block;
    position: absolute;
  }
}
