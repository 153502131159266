.p-message {
  margin: 0;
  border-radius: $borderRadius;
  width: 100%;
  align-items: center;

  .p-message-wrapper {
    display: block;
    width: 100%;
    .p-inline-message {
      padding: 12px 80px;
      display: flex;
      > * {
        margin: auto;
      }
      .p-inline-message-text {
        flex-grow: 1;
      }
    }
    .p-inline-message.p-inline-message-info {
      background: $geaui-active-blue-110;
      border: 0 none;
      border-width: 1px;
    }
    .p-inline-message.p-inline-message-warn {
      background: $geaui-radiant-orange-80;
      border: 0 none;
      border-width: 1px;
    }
  }

  .p-message-close {
    width: $actionIconWidth;
    height: $actionIconHeight;
    border-radius: $actionIconBorderRadius;
    background: transparent;
    transition: $actionIconTransition;

    &:hover {
      background: $geaui-pure-white-brightness-30;
    }

    &:focus {
      @include focused();
    }
  }

  &.p-message-info {
    background: $geaui-active-blue-110;
    border: $infoMessageBorder;
    border-width: $messageBorderWidth;
    color: $infoMessageTextColor;

    .p-message-icon {
      color: $infoMessageIconColor;
    }

    .p-message-close {
      color: $infoMessageIconColor;
    }
  }

  &.p-message-warn {
    background: $geaui-radiant-orange-80;
    border: $warningMessageBorder;
    border-width: $messageBorderWidth;
    color: $geaui-pure-white;

    .p-message-icon {
      color: $geaui-pure-white;
    }

    .p-message-close {
      color: $geaui-pure-white;
    }
  }

  &.p-message-error {
    background: $geaui-tertiary-red-70;
    border: $errorMessageBorder;
    border-width: $messageBorderWidth;
    color: $errorMessageTextColor;

    .p-message-icon {
      color: $errorMessageIconColor;
    }

    .p-message-close {
      color: $errorMessageIconColor;
    }
  }

  .p-message-text {
    font-size: $messageTextFontSize;
    font-weight: $messageTextFontWeight;
  }

  .p-message-icon {
    font-size: $messageIconFontSize;
    margin-right: $inlineSpacing;
  }

  .p-icon {
    width: $messageIconFontSize;
    height: $messageIconFontSize;
  }

  .p-message-summary {
    @include gea-font-heading(16);
    text-align: left;
  }

  .p-message-detail {
    margin-left: $inlineSpacing;
    @include gea-font-body(16);
    text-align: left;
  }
}

.gea-banner-message-button {
  @include gea-font-body(16);
  transition: 300ms ease-out;
  width: 69px;
  height: 32px;
  position: absolute;
  top: 11px;
  right: 85px;
  outline: none;
  background-color: transparent;
  padding: 0;
  border: 2px solid $geaui-pure-white;
  color: $geaui-pure-white;
  border-radius: 20px;
  font-size: 14px;
}

.gea-banner-icon-container {
  display: flex;
  > * {
    padding-right: 20px;
  }
}
.gea-banner-text-container {
  padding-right: 20px;
}
.p-message-wrapper {
  .gea-banner-close-button:hover {
    cursor: pointer;
    .icon-v2-stroke-white path {
      stroke: $geaui-secondary-black;
    }
  }
}

.icon-v2-stroke-black path {
  stroke: $geaui-primary-black;
}
.gea-snackbar-icon-container {
  display: flex;
  background-clip: content-box;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.gea-snackbar-text-container {
  padding: 16px 24px;
  padding-right: 55px;
  background-color: $geaui-pure-white;
}
.p-inline-snackbar-message {
  padding: 0 0;
  margin: $inlineMessageMargin;
  border-radius: $borderRadius;
}

.gea-snackbar-close-button {
  display: flex;
  > * {
    display: flex;
    padding: 16px 24px;
    background-clip: content-box;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 2px;
    height: 100%;
  }
}

.gea-snackbar-first-icon {
  padding-right: 14px;
  padding-left: 12px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  @include gea-font-body(14);
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  @include gea-font-heading(16);
  &.gea-message-toast-summary-info {
    color: $geaui-active-blue-120;
  }
  &.gea-message-toast-summary-success {
    color: $geaui-tertiary-green-80;
  }
  &.gea-message-toast-summary-unknown {
    color: $geaui-primary-black;
  }
}

@media (max-width: 767px) and (min-width: 480px) {
  .p-message .p-message-wrapper .p-inline-message {
    padding: 12px 40px;
  }
}

@media (max-width: 479px) and (min-width: 360px) {
  .p-message .p-message-wrapper .p-inline-message {
    padding: 12px 20px;
  }
}
