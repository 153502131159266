
.p-tooltip {
  max-width: 300px;
  opacity: 0;
  transition: opacity 150ms ease-out;
  z-index: 1001;

  .p-tooltip-text {
    @include gea-font-heading(16);
    background: rgba(29, 29, 29, 1);
    padding: 16px;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;
    color: $tooltipTextColor;
  }

  &.fit-content-tooltip {
    max-width: none;
    .p-tooltip-text {
      width: fit-content;
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: rgba(29, 29, 29, 1);
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: rgba(29, 29, 29, 1);
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: rgba(29, 29, 29, 1);
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: rgba(29, 29, 29, 1);
    }
  }
}

.p-tooltip-light-blue.p-tooltip {
  max-width: 300px;

  .p-tooltip-text {
    @include gea-font-heading(16);
    background: $geaui-active-blue-50;
    color: $geaui-primary-black;
    padding: 16px;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: $geaui-active-blue-50;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: $geaui-active-blue-50;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $geaui-active-blue-50;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $geaui-active-blue-50;
    }
  }
}


// bigger tooltip arrow
$geaArrowSize: .5em;
$geaArrowMargin: -.5rem;
$geaArrowOffset: 4px;

.p-tooltip-right {
  margin-left: 15px;

  .p-tooltip-arrow {
    top: 50%;
    left: $geaArrowOffset * -1;
    margin-top: $geaArrowMargin;
    border-width: $geaArrowSize $geaArrowSize $geaArrowSize 0;
  }
}

.p-tooltip-left {
  margin-left: -15px;

  .p-tooltip-arrow {
    top: 50%;
    right: $geaArrowOffset * -1;
    margin-top: $geaArrowMargin;
    border-width: $geaArrowSize 0 $geaArrowSize $geaArrowSize;
  }
}

.p-tooltip-top {
  margin-top: -15px;

  .p-tooltip-arrow {
    bottom: $geaArrowOffset * -1;
    left: 50%;
    margin-left: $geaArrowMargin;
    border-width: $geaArrowSize $geaArrowSize 0;
  }
}

.p-tooltip-bottom {
  margin-top: 15px;

  .p-tooltip-arrow {
    top: $geaArrowOffset * -1;
    left: 50%;
    margin-left: $geaArrowMargin;
    border-width: 0 $geaArrowSize $geaArrowSize;
  }
}

$geaTooltopArrowDistance: calc(16px + ($geaArrowSize/2));
.p-tooltip-arrow-position-start {

  &.p-tooltip-top, &.p-tooltip-bottom {
    &.p-tooltip {
      transform: translate(calc(50% - $geaTooltopArrowDistance), 0);
    }
    .p-tooltip-arrow {
      left: $geaTooltopArrowDistance;
    }

  }

  &.p-tooltip-left, &.p-tooltip-right {
    &.p-tooltip {
      transform: translate(0%, calc(50% - $geaTooltopArrowDistance));
    }

    .p-tooltip-arrow {
      top: $geaTooltopArrowDistance;
    }
  }
}


.p-tooltip-arrow-position-end {

  &.p-tooltip-top, &.p-tooltip-bottom {
    &.p-tooltip {
      transform: translate(calc(-50% + $geaTooltopArrowDistance), 0);
    }
    .p-tooltip-arrow {
      left: calc(100% - $geaTooltopArrowDistance);
    }
  }

  &.p-tooltip-left, &.p-tooltip-right {
    &.p-tooltip {
      transform: translate(0, calc(-50% + $geaTooltopArrowDistance));
    }
    .p-tooltip-arrow {
      top: calc(100% - $geaTooltopArrowDistance);
    }
  }
}
