@use 'sass:math';

$placeholder-padding: 12px 20px 13px 20px;

.tree-select-container {
  .p-treeselect {
    background: $inputBg;
    border: $inputBorder;
    transition: $formElementTransition;
    border-radius: $borderRadius;
    width: 100%;

    &.p-disabled {
      border-color: $geaui-grey-05;
      .p-treeselect-label {
        &.p-placeholder {
          color: $geaui-grey-01;
        }
      }
      .icon-v2 svg path{
        stroke: $geaui-grey-01
      }
    }

    &:not(.p-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
      border-color: $geaui-primary;
    }

    .p-treeselect-label {
      padding: $placeholder-padding;
      transition: $formElementTransition;

      &.p-placeholder {
        color: $geaui-pure-black;
      }
    }

    &.p-treeselect-chip {
      .p-treeselect-label {
        gap: $inlineSpacing;
      }

      .p-treeselect-token {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
        background: $highlightBg;
        color: $highlightTextColor;
        border-radius: $borderRadius;

        .p-treeselect-token-icon {
          margin-left: $inlineSpacing;
        }
      }
    }

    .p-treeselect-trigger {
      background: transparent;
      color: $inputIconColor;
      width: $inputGroupAddOnMinWidth;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  .disable-label {
    color: $geaui-grey-01;
  }

  p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    @include invalid-input();
  }

  .p-inputwrapper-filled {
    .p-treeselect {
      &.p-treeselect-chip {
        .p-treeselect-label {
          padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
        }
      }
    }
  }

  .p-treeselect-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;
    overflow: auto;
    position: relative;
    border-left: 2px solid $geaui-primary;
    border-right: 2px solid $geaui-primary;
    border-bottom: 2px solid $geaui-primary;
    top: -4px;
    left: -1.9px;

    .p-treeselect-header {
      padding: $inputListHeaderPadding;
      border-bottom: $inputListHeaderBorder;
      color: $inputListHeaderTextColor;
      background: $inputOverlayHeaderBg;
      margin: $inputListHeaderMargin;
      border-top-right-radius: $borderRadius;
      border-top-left-radius: $borderRadius;
      position: relative;

      .p-treeselect-filter-container {
        margin-right: $inlineSpacing;
        position: relative;
        width: 100%;

        .p-treeselect-filter {
          padding-right: nth($inputPadding, 2) + $primeIconFontSize;
          border-right: none;
          border-left: none;
          border-top: none;
          border-bottom: 2px solid $geaui-primary;

          &::placeholder {
            color: $geaui-primary;
            font-weight: 500;
          }
        }

        .p-treeselect-filter-icon {
          right: nth($inputPadding, 2);
          color: $inputIconColor;
        }

        &.p-treeselect-clearable-filter {
          .p-treeselect-filter {
            padding-right: 2 * (nth($inputPadding, 2) + $primeIconFontSize);
          }

          .p-treeselect-filter-clear-icon {
            right: (2 * nth($inputPadding, 2)) + $primeIconFontSize;
          }
        }

        .p-inputtext {
          padding-right: 50px;
          padding-left: 0;
        }

        .gea-treeselect-search-icon {
          position: absolute;
          top: 15px;
          right: 0px;
          visibility: hidden;
        }

        .gea-treeselect-close-icon {
          position: absolute;
          cursor: pointer;
          top: 15px;
          right: 0px;
          transition: transform 0.1s;
          transition-delay: 0.1s;
        }

        .p-inputtext:enabled:focus {
          border-right: none;
          border-left: none;
          border-top: none;
        }

        .p-inputtext:enabled:focus ~ .gea-treeselect-close-icon {
          transform: translateX(-30px);
          transition: transform 0.1s;
        }

        .p-inputtext:enabled:focus ~ .gea-treeselect-search-icon {
          visibility: visible;
        }
      }

      .p-checkbox {
        margin-right: $inlineSpacing;
        display: none;
      }

      .p-treeselect-close {
        margin-left: $inlineSpacing;
        @include action-icon();
        display: none;
      }
    }

    .p-treeselect-items-wrapper {
      .p-tree {
        border: 0 none;
      }

      .p-treeselect-empty-message {
        padding: $inputListItemPadding;
        color: $inputListItemTextColor;
        background: $inputListItemBg;
      }
    }
  }

  .p-input-filled {
    .p-treeselect {
      background: $inputFilledBg;

      &:not(.p-disabled):hover {
        background-color: $inputFilledHoverBg;
      }

      &:not(.p-disabled).p-focus {
        background-color: $geaui-focused-outline;
      }
    }
  }

  p-treeselect.p-treeselect-clearable {
    .p-treeselect-label-container {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-treeselect-clear-icon {
      color: $inputIconColor;
      right: $inputGroupAddOnMinWidth;
    }
  }

  .p-tree {
    background: transparent;
    color: $geaui-primary-black;
    padding: 0;
    border-radius: $borderRadius;
    font-size: 16px;

    .p-tree-container {
      padding: 0;
      position: relative;
      background-color: #F0F0F0;

      .p-treenode {
        padding-right: 0;

        .p-treenode-content {
          border-radius: $borderRadius;
          transition: $listItemTransition;
          padding: 12px 24px 12px 16px;
          position: relative;

          .p-tree-toggler {
            display: block;
            margin: 0 10px
          }

          .p-treenode-label {
            width: 100%;
          }

          .p-treenode-icon {
            margin-right: $inlineSpacing;
            color: $treeNodeIconColor;
          }

          .p-treenode-selectable {
              svg {
                stroke: var(--geaui-vibrant-blue) ;
              }
          }

          .p-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }

          &.p-treenode-selectable:not(.p-highlight):hover {
            background: $geaui-vibrant-blue-100;
            color: $geaui-vibrant-blue;
            .icon-v2 svg path{
              stroke: $geaui-vibrant-blue;
            }
          }

          .p-checkbox {
            margin-right: $inlineSpacing;
            position: absolute;
            right: 10px;

            .p-indeterminate {
              .p-checkbox-icon {
                color: $textColor;
              }
            }
          }

          &.p-highlight {
            background: $inputListItemSelected;
            color: $geaui-vibrant-blue;

            ~ .p-treenode-children {
              background:$inputListItemSelected ;
            }

            .p-tree-toggler-icon{
                .icon-v2 svg path{
                  stroke: $geaui-vibrant-blue;
                }
            }

            .p-tree-toggler,
            .p-treenode-icon {
              color: $highlightTextColor;

              &:hover {
                color: $highlightTextColor;
              }
            }
          }

          &.p-treenode-dragover {
            background: $inputListItemHoverBg;
            color: $inputListItemTextHoverColor;
          }
        }
      }
    }

    .p-tree-filter-container {
      margin-bottom: $inlineSpacing;

      .p-tree-filter {
        width: 100%;
        padding-right: nth($inputPadding, 2) + $primeIconFontSize;
      }

      .p-tree-filter-icon {
        right: nth($inputPadding, 2);
        color: $inputIconColor;
      }
    }

    .p-treenode-children {
      padding: $treeNodeChildrenPadding;
    }

    .p-tree-loading-icon {
      font-size: $loadingIconFontSize;

      &.p-icon {
        width: $loadingIconFontSize;
        height: $loadingIconFontSize;
      }
    }

    .p-treenode-droppoint {
      &.p-treenode-droppoint-active {
        background-color: scale-color($highlightBg, $lightness: -20%);
      }
    }

    &.p-tree-horizontal {
      .p-treenode {
        .p-treenode-content {
          border-radius: $borderRadius;
          border: $panelContentBorder;
          background-color: $panelContentBg;
          color: $panelContentTextColor;
          padding: $treeNodeContentPadding;
          transition: $listItemTransition;

          &.p-highlight {
            background-color: $highlightBg;
            color: $highlightTextColor;


            .p-treenode-icon {
              color: $highlightTextColor;
            }
          }

          .p-tree-toggler {
            margin-right: $inlineSpacing;
          }

          .p-treenode-icon {
            color: $treeNodeIconColor;
            margin-right: $inlineSpacing;
          }

          .p-checkbox {
            margin-right: $inlineSpacing;
          }

          .p-treenode-label:not(.p-highlight):hover {
            background-color: inherit;
            color: inherit;
          }

          &.p-treenode-selectable:not(.p-highlight):hover {
            background: $inputListItemHoverBg;
            color: $inputListItemTextHoverColor;
          }

          &:focus {
            @include focused();
          }
        }
      }
    }
  }

  li:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
   }
}