.p-checkbox {
  width: $checkboxWidth;
  height: $checkboxHeight;

  .p-checkbox-box {
    border: $checkboxBorder;
    background: $inputBg;
    width: $checkboxWidth;
    height: $checkboxHeight;
    color: $textColor;
    border-radius: $borderRadius;
    transition: $formElementTransition;

    &:hover {
      background: $geaui-secondary-hover;
    }
    .p-checkbox-icon {
      transition-duration: $transitionDuration;
      color: $geaui-primary;
      font-size: $checkboxIconFontSize;
    }

    .p-icon {
      width: $checkboxIconFontSize;
      height: $checkboxIconFontSize;
    }

    &.p-highlight {
      border-color: $geaui-button-default;
      background: $checkboxActiveBg;
    }
  }

  &.p-checkbox-disabled {
    background-color: $geaui-grey-04;
    border-color: $geaui-grey-03;
    .p-highlight.p-disabled {
      border-color: $geaui-grey-03;
      color: $geaui-grey-01;
      path {
        stroke: $geaui-grey-01;
      }
    }
    .p-disabled {
      background: inherit;
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $geaui-primary-hover;
      }

      &.p-focus {
        border-color: $geaui-focused-outline;
      }

      &.p-focus:hover {
        border-color: $geaui-primary-hover;
      }

      &.p-highlight:hover {
        border-color: $geaui-primary-hover;
        background: $checkboxActiveHoverBg;
        color: $checkboxIconActiveHoverColor;
      }
    }
  }
}

.p-checkbox-icon.gea-checkbox-icon {
  height: 14px;
  width: 14px;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  @include invalid-input();
}

.p-input-filled {
  .p-checkbox {
    .p-checkbox-box {
      background-color: $inputFilledBg;

      &.p-highlight {
        background: $checkboxActiveBg;
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $inputFilledHoverBg;
        }

        &.p-highlight:hover {
          background: $checkboxActiveHoverBg;
        }
      }
    }
  }
}

.p-checkbox-label {
  margin-left: 16px;
}

@if ($highlightBg == $checkboxActiveBg) {
  .p-highlight {
    .p-checkbox {
      .p-checkbox-box {
        border-color: $checkboxIconActiveColor;
      }
    }
  }
}

.p-checkbox .p-checkbox-box:active {
  &:not(.p-checkbox-disabled){
    border-color: $geaui-primary-pressed !important;
  }
}
