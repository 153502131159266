.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
  background-color: red;
}

.gea-textfield-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 25px;
  height: 100%;

  .p-float-label > label {
    height: 20px;
  }
}

.p-float-label textarea ~ label {
  top: 22px !important;
}

.p-float-label.gea-always-float-label textarea ~ label {
  top: -14px !important;
  left: -8px;
  font-size: 14px;
}

textarea::placeholder {
  opacity: 1 !important;
  color: $geaui-pure-black;
}

.gea-textfield {
  height: 160px;
  overflow: unset;
  white-space: pre-wrap;
  text-overflow: unset;

  max-width: 100%;
  min-height: 50px;
  min-width: 100px;

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
}
