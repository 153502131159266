
/// Font size of button
$font-size: 40px;

/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 8px 22px;

/// Padding of a small button
/// @group button
$buttonSmallPadding: 6px 16px;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.5rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: .5rem 0;

/// Background of a button
/// @group button
$buttonBg: $geaui-primary;

/// Text color of a button
/// @group button
$buttonTextColor: $geaui-pure-white;

/// Border of a button
/// @group button
$buttonBorder: 1px solid $geaui-primary;

/// Background of a button in hover state
/// @group button
$buttonHoverBg: $geaui-primary-hover;

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $geaui-pure-white;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $geaui-primary-hover;

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: $geaui-primary-pressed;

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $geaui-pure-white;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $geaui-primary-pressed;

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity:.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity:.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder:1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor:#6c757d;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor:#e9ecef;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor:#dee2e6;

/// Background of a secondary button
/// @group button
$secondaryButtonBg: $geaui-pure-white;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: $geaui-button-default;

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 2px solid $geaui-button-default;

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: $geaui-secondary-hover;

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: $geaui-button-default;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: $geaui-button-default;

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: $geaui-secondary-pressed;

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: $geaui-button-default;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: $geaui-button-default;

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem #B0BEC5;

/// Background of a red cancel button
/// @group button
$redCancelButtonBg: $geaui-tertiary-red-60;

/// Text color of a red cancel button
/// @group button
$redCancelButtonTextColor: $geaui-primary-black;

/// Border of a red cancel button
/// @group button
$redCancelButtonBorder: none;

/// Background of a cred cancel button in hover state
/// @group button
$redCancelButtonHoverBg: $geaui-tertiary-red-50;

/// Text color of a red cancel button in hover state
/// @group button
$redCancelButtonTextHoverColor: $geaui-primary-black;

/// Border color of a red cancel button in hover state
/// @group button
$redCancelButtonHoverBorderColor: none;

/// Background of a red cancel button in pressed state
/// @group button
$redCancelButtonActiveBg: $geaui-tertiary-red-70;

/// Text color of a red cancel button in pressed state
/// @group button
$redCancelButtonTextActiveColor: $geaui-primary-black;

/// Border color of a red cancel button in pressed state
/// @group button
$redCancelButtonActiveBorderColor: none;

/// Box shadow of a red cancel button in focused state
/// @group button
$redCancelButtonFocusShadow: 0 0 0 0.2rem #B0BEC5;

/// Background of an info button
/// @group button
$infoButtonBg: #03A9F4;

/// Text color of an info button
/// @group button
$infoButtonTextColor: $geaui-pure-white;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid #03A9F4;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: #039BE5;

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: $geaui-pure-white;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: #039BE5;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: #0288D1;

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: $geaui-pure-white;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: #0288D1;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: #4CAF50;

/// Text color of a success button
/// @group button
$successButtonTextColor: $geaui-pure-white;

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid #4CAF50;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: #43A047;

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: $geaui-pure-white;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: #43A047;

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: #388E3C;

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: $geaui-pure-white;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: #388E3C;

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);

/// Background of a warning button
/// @group button
$warningButtonBg: #FFC107;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: $textColor;

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid #FFC107;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: #FFB300;

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: $textColor;

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: #FFB300;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: #FFA000;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $textColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: #FFA000;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg:#9C27B0;

/// Text color of a help button
/// @group button
$helpButtonTextColor:$geaui-pure-white;

/// Border of a help button
/// @group button
$helpButtonBorder:1px solid #9C27B0;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg:#8E24AA;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor:$geaui-pure-white;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor:#8E24AA;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg:#7B1FA2;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor:$geaui-pure-white;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor:#7B1FA2;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow:0 0 0 0.2rem #CE93D8;

/// Background of a danger button
/// @group button
$dangerButtonBg: #f44336;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: $geaui-pure-white;

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid #f44336;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: #e53935;

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: $geaui-pure-white;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: #e53935;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: #d32f2f;

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: $geaui-pure-white;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: #d32f2f;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);

/// Text color of a link button
/// @group button
$linkButtonColor:$geaui-active-blue-55;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor:$geaui-active-blue-55;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration:underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: $geaui-pure-white;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 2px solid $geaui-primary;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $geaui-button-default;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: $geaui-secondary-hover;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: $geaui-primary-hover;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $geaui-button-default;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $geaui-button-default;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $geaui-button-default;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $geaui-pure-white;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $geaui-pure-white;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $geaui-primary-hover;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $geaui-primary-hover;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $geaui-pure-white;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $geaui-pure-white;

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial
/// @group button
$speedDialActionTextColor: $geaui-pure-white;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: $geaui-pure-white;
