.p-sidebar {
  background: $overlayContentBg;
  color: $panelContentTextColor;
  border: $overlayContentBorder;
  box-shadow: $overlayContainerShadow;

  .p-sidebar-header {
    padding: $panelHeaderPadding;

    .p-sidebar-close,
    .p-sidebar-icon {
      @include action-icon();
    }

    & + .p-sidebar-content {
      padding-top: 0;
    }
  }

  .p-sidebar-content {
    padding: $panelContentPadding;
  }

  .p-sidebar-footer {
    padding: $panelHeaderPadding;
  }
}

geaui-bottom-sheet {
  .p-sidebar {
      height: 70%;

    .handle {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 36px;

      &::after {
        display: block;
        align-self: center;
        content: ' ';
        background-color: var(--geaui-secondary-black);
        opacity: 40%;
        border-radius: 100px;
        width: 32px;
        height: 4px;
      }
    }

    > .p-sidebar-header {
      height: 26px;
      margin-top: 36px;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .back-button {
          margin-right: 1rem;

          :hover {
            cursor: pointer;
          }
        }
      }

      .p-sidebar-icon:enabled:hover {
        color: $geaui-pure-black;
        background: none;
      }

      > .p-sidebar-close {
        color: $geaui-pure-black;
      }
    }

    .p-sidebar-content {
      margin-top: 1.5rem;
    }
  }
}

.gea-side-modal-container {
  $content-padding: 24px;

  .p-sidebar {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;
    width: 420px;

    .p-sidebar-header {
      padding: 0;
      min-height: 74px;
      @include gea-font-heading(20);
      border-bottom: 2px solid $geaui-grey-05;
      position: relative;
      display: flex;
      flex-direction: column;

      .gea-side-modal-header {
        display: flex;
        align-items: center;
        align-self: start;
        width: 100%;
        position: relative;
        height: 72px;
         span {
           color: $geaui-pure-black;
           padding: $content-padding 16px $content-padding $content-padding;
         }

        .gea-side-modal-close-icon {
          position: absolute;
          right: 32px;
          cursor: pointer;
        }
      }

      .gea-side-modal-sub-header {
        @include gea-font-heading(14);
        align-self: start;
        overflow-y: scroll;
        padding: 0 $content-padding;
        width: 100%
      }

      .p-sidebar-close,
      .p-sidebar-icon {
        @include action-icon();
        position: absolute;
        top: 20px;
        right: 30px;
      }

      & + .p-sidebar-content {
        padding-top: 0;
      }
    }

    .p-sidebar-content {
      padding: $content-padding;
    }

    .p-sidebar-footer {
      padding: $content-padding;
    }
  }
}
